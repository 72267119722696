import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Container, Layout, SEO } from '../components'
import { htmlToMarkdown } from '../lib'

const ImpressumPage = (): React.ReactElement => {
  const {
    wpPage: {
      content,
      seo: { title, metaDesc, opengraphImage },
    },
  } = useStaticQuery(graphql`
    query ImprintQuery {
      wpPage(id: { eq: "cG9zdDoxNTk4" }) {
        content
        seo {
          title
          metaDesc
          opengraphImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        wordpressTitle={title}
        description={metaDesc}
        socialImageRelativeUrl={opengraphImage?.localFile.publicURL}
      />

      <Layout>
        <Container>
          {/* <Headline tagName="h1">Impressum</Headline> */}

          <ReactMarkdown className="formattedHTML">
            {htmlToMarkdown(content)}
          </ReactMarkdown>
        </Container>
      </Layout>
    </>
  )
}

export default ImpressumPage
